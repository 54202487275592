import Config from "./includes/Config";

import WidgetBodyCreator from "./includes/WidgetBodyCreator";
import WidgetCodeFormatter from "./includes/Helpers/WidgetCodeFormatter";
import GroupDataService from "./includes/Services/GroupDataService";
import SubscriptionsService from "./includes/Services/SubscriptionsService";
import BotService from "./includes/Services/BotService";
import Subscribes from "./includes/Services/Subscribes";
import ShareService from "./includes/Services/ShareService";
import PagesService from "./includes/Services/PagesService";
import VarReplacer from "./includes/Helpers/VarReplacer";
import LaunchParams from "./includes/Services/LaunchParams";
import FetchRequestClient from "./includes/Services/Clients/FetchRequestClient";
import XhrRequestClient from "./includes/Services/Clients/XhrRequestClient";
import { createUrlForWidget } from "./includes/Helpers/Helpers";
import Logger from "./includes/Services/Logger";
import CookieProvider from "./includes/Helpers/CookieProvider";
import LocalStorageProvider from "./includes/Helpers/LocalStorageProvider";
import VariablesService from "./includes/Services/VariablesService";

/**
 * Контейнер внедрения зависимостей
 * Отвечает за конфигурацию основных модулей. Только он знает как и какие зависимости внедрять в модули системы
 * https://habr.com/ru/post/350708/
 *
 * Далее в коде используются только экземпляры полученные только из контейнера.
 * Преимущественно это различные прикладные сервисы
 *
 * Преимущества такого подхода
 * - Повышает возможность параиспользования компонента. Зависимости могут внедрены в зависимости от контекста
 * - Компоненты можно тестировать
 * - Упрощает создание экземпляров и их внедрение
 */

type ServiceTypes =
    | "BotService"
    | "logger"
    | "LaunchParams"
    | "WidgetBodyCreator"
    | "GroupDataService"
    | "SubscriptionsService"
    | "SubscribeService"
    | "ShareService"
    | "PagesService"
    | "VariablesService";

type ObjectType<T> = T extends "BotService"
    ? BotService
    : T extends "logger"
    ? Logger
    : T extends "LaunchParams"
    ? LaunchParams
    : T extends "WidgetBodyCreator"
    ? WidgetBodyCreator
    : T extends "GroupDataService"
    ? GroupDataService
    : T extends "SubscriptionsService"
    ? SubscriptionsService
    : T extends "SubscribeService"
    ? Subscribes
    : T extends "ShareService"
    ? ShareService
    : T extends "PagesService"
    ? PagesService
    : T extends "VariablesService"
    ? VariablesService
    : never;

class DependencyContainer {
    dependecies: any;

    constructor() {
        this.dependecies = {};
    }

    set(name: string, closure: Function | any) {
        if (!this.dependecies[name]) {
            this.dependecies[name] = {
                type: "simple",
                closure: closure,
            };
        } else {
            throw new Error(
                `Dependency container - entity ${name} already set`
            );
        }
    }

    setSingleton(name: string, entity: any) {
        if (!this.dependecies[name]) {
            this.dependecies[name] = {
                type: "singleton",
                entity: entity,
            };
        } else {
            throw new Error(
                `Dependency container - entity ${name} already set`
            );
        }
    }

    get<T extends ServiceTypes>(name: T): ObjectType<T> {
        if (this.dependecies[name]) {
            const dependency = this.dependecies[name];
            if (dependency.type === "singleton") {
                return dependency.entity;
            } else {
                return dependency.closure();
            }
        } else {
            throw new Error(`Dependency ${name} does not set`);
        }
    }

    init() {
        /**
         * Инициализация контейнера зависимостей
         * Сюда можно добавить некий полезный код
         */
    }
}

/**
 * Инициализируем контейнер зависимостей
 */
const container = new DependencyContainer();

/**
 * Компонент для получения информации о параметрах запуска приложения
 */
container.setSingleton(
    "LaunchParams",
    new LaunchParams(window.location.search, window.location.hash)
);

/**
 * Компонент для логирования ошибок и отладочной информации
 */
container.setSingleton(
    "logger",
    new Logger(container.get("LaunchParams"), Config.rootHost)
);

/**
 * Конструктор кода для метода publish api ВК
 */
container.setSingleton(
    "WidgetBodyCreator",
    new WidgetBodyCreator(
        new WidgetCodeFormatter(),
        new VarReplacer(),
        createUrlForWidget
    )
);

/**
 * Сервис для управления основными настройками приложения
 */
container.setSingleton(
    "GroupDataService",
    new GroupDataService(
        Config.getMainAppHost(),
        { ...container.get("LaunchParams").params },
        new FetchRequestClient()
    )
);

/**
 * Сервис для получения и управления каталогом групп подписок
 */
container.setSingleton(
    "SubscriptionsService",
    new SubscriptionsService(
        Config.getMainAppHost(),
        container.get("logger"),
        new FetchRequestClient()
    )
);

/**
 * Сервис для управления ботами
 */
container.setSingleton(
    "BotService",
    new BotService(
        Config.getMainAppHost(),
        container.get("logger"),
        new XhrRequestClient(),
        container.get("LaunchParams"),
        CookieProvider,
        LocalStorageProvider
    )
);

/**
 * Сервис для действий с группами подписок - подписка, отписка, отписка от всех и тд.
 */
container.setSingleton(
    "SubscribeService",
    new Subscribes(Config.getMainAppHost(), container.get("logger"))
);

/**
 * Сервис для управление коллекциями "Поделится"
 */
container.setSingleton(
    "ShareService",
    new ShareService({ ...container.get("LaunchParams") })
);

/**
 * Сервис для управления лендингами
 */
container.setSingleton(
    "PagesService",
    new PagesService(
        { ...container.get("LaunchParams") },
        container.get("logger")
    )
);

/**
 * Сервис для переменных senler
 */
container.setSingleton(
    "VariablesService",
    new VariablesService(
        { ...container.get("LaunchParams") },
        container.get("logger")
    )
);

export default Object.freeze(container);
